import { request } from '@/network/request'
import qs from 'qs'
import { nanoid } from 'nanoid'

//上传图片
export function uploadBase64(fileName, imgBase64) {
  return request({
    method: 'post',
    url: '/trade/dopoimg',
    data: [
      {
        sid: nanoid(),
        base64: imgBase64,
        name: fileName
      }
    ]
  })
}

// 提交信息（修改信息）
export function publishMsg(data) {
  return request({
    method: 'post',
    url: '/trade/publish_save',
    data: qs.stringify(data)
  })
}
