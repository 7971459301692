<template>
  <div style="margin:20px 20px 20px 20px">
    <el-upload
      list-type="picture-card"
      action="#"
      ref="imgUpload"
      :multiple="upload.multiple"
      :show-file-list="upload.showList"
      :http-request="httpRequest"
      :before-upload="beforeUpload"
      :on-change="fileChange"
      :limit="upload.limit"
      :accept="upload.accept"
      :auto-upload="upload.autoUpload"
      :headers="uploadHeaders"
      class="form__head-icon-upload"
      :class="{ hide: hideUpload }"
    >
      <i slot="default" class="el-icon-plus"></i>
      <div slot="file" slot-scope="{ file }">
        <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
        <span class="el-upload-list__item-actions">
          <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
            <i class="el-icon-zoom-in"></i>
          </span>
          <span v-if="!disabled" class="el-upload-list__item-delete" @click="handleRemove(file)">
            <i class="el-icon-delete"></i>
          </span>
        </span>
      </div>
    </el-upload>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>

    <el-form ref="form" :model="form" style="margin-top:40px">
      <el-form-item>
        <el-input v-model="form.title" placeholder="填写标题"></el-input>
      </el-form-item>
      <el-form-item>
        <el-input
          type="textarea"
          :autosize="{ minRows: 4, maxRows: 10 }"
          v-model="form.content"
          placeholder="填写内容"
        ></el-input>
      </el-form-item>

      <el-form-item>
        <el-select v-model="form.type" placeholder="话题">
          <el-option v-for="item in typelist" :key="item.id" :value="item.id" :label="item.name"> </el-option>
        </el-select>
      </el-form-item>

      <div id="div_address" v-html="form.address"></div>
      <div style="padding-bottom:20px" @click="btngosearch"><i class="el-icon-location-outline"></i> 添加地点</div>

      <el-form-item>
        <el-button type="primary" style="width:95%" @click="submitForm">发布</el-button>
      </el-form-item>

      <!--选择地点-->
      <place-search @btnout="btnout" class="place-search" id="place-search" ref="homeSearch"></place-search>
    </el-form>
  </div>
</template>

<script>
import { getType } from 'network/home'
import { publishMsg } from '@/network/publish'
import PlaceSearch from '@/views/common/map/PlaceSearch'
import { getAliToken, Client } from '@/network/ossClient'
import { getVideoCover, randomString } from '@/network/common'

export default {
  name: 'More',
  components: {
    PlaceSearch
  },
  data() {
    return {
      filePath: [],
      hideUpload: false,
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,
      uploadHeaders: {
        authorization: '*'
      },
      upload: {
        multiple: true,
        autoUpload: false,
        listType: 'picture-card',
        accept: '.jpg,.jpeg,.png,.gif,.JPG,.JPEG,.PBG,.GIF,.mp4,.MP4',
        limit: 9
      },

      form: {
        coverImg: '',
        content: '',
        title: '',
        type: '',
        address: '',
        longitude: '',
        latitude: ''
      },
      typeid: null,
      cityid: null,
      typelist: [],
      user_id: null
    }
  },
  created() {
    this.user_id = window.sessionStorage.getItem('userId')
    this.getType()
    this.getAliToken()
  },
  methods: {
    btngosearch() {
      let placesearch = document.getElementById('place-search')
      placesearch.style.left = 0 + 'vh'
      const h = document.documentElement.clientHeight
      placesearch.style.height = h + 'px'
    },
    btnout(item) {
      if (item) {
        this.form.address = item.name
        this.form.longitude = item.location.lng
        this.form.latitude = item.location.lat
      }
      let placesearch = document.getElementById('place-search')
      placesearch.style.left = 100 + 'vh'
      placesearch.style.height = 0 + 'px'
    },
    handleRemove(file) {
      let arr = this.$refs.imgUpload.uploadFiles
      let index = arr.indexOf(file)
      arr.splice(index, 1)
      this.hideUpload = false
    },
    fileChange(file, fileList) {
      if (file.raw && file.raw.type == 'video/mp4') {
        getVideoCover(file, this)
        if (fileList.length > 1) {
          this.$message('只能上传9张图片或一个视频')
          this.$refs.imgUpload.clearFiles()
          return
        }
        this.hideUpload = true
        return
      } else {
        this.form.coverImg = ''
      }
      this.hideUpload = fileList.length >= this.upload.limit
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    getAliToken() {
      getAliToken().then((res) => {
        if (res.status == 200) {
          this.dataObj = {
            region: 'oss-cn-shanghai',
            bucket: 'sch-bucket',
            accessKeyId: res.data.AccessKeyId,
            accessKeySecret: res.data.AccessKeySecret,
            stsToken: res.data.SecurityToken
          }
        }
      })
    },
    beforeUpload() {
      this.getAliToken()
    },
    async httpRequest(option) {
      var self = this
      //上传OSS
      try {
        self.disabled = true
        const client = Client(this.dataObj)
        const file = option.file
        const random_name = randomString(6) + '_' + new Date().getTime() + '.' + file.name.split('.').pop()
        await client
          .multipartUpload(random_name, file, {
            progress: async function(p) {
              let e = {}
              e.percent = p * 100
            }
          })
          .then(({ res }) => {
            if (res.statusCode === 200) {
              const url = res.requestUrls[0]
              self.filePath.push(url.substring(0, url.indexOf('?')))
              if (self.filePath.length === self.$refs.imgUpload.uploadFiles.length) {
                this.pushMsg()
              }
            } else {
              self.disabled = false
              option.onError('上传失败')
            }
          })
          .catch((error) => {
            self.disabled = false
            option.onError('上传失败', error)
          })
      } catch (error) {
        console.error(error)
        this.disabled = false
        option.onError('上传失败')
      }
    },
    pushMsg() {
      let self = this
      const data = {
        cat_id1: self.form.type,
        cat_id2: self.form.type,
        info: self.form.content,
        title: self.form.title,
        img: self.filePath,
        coverImg: self.form.coverImg,
        address: self.form.address,
        longitude: self.form.longitude,
        latitude: self.form.latitude
      }
      console.log(data)
      const param = data
      publishMsg(data, param).then((res) => {
        if (res.data) {
          this.$message({
            message: res.data.msg,
            type: 'info'
          })
          if (res.data.status == 1) {
            self.filePath = []
            this.$router.push({
              path: '/usernote'
            })
          }
        }
      })
    },
    getType() {
      getType().then((res) => {
        this.typelist = res.data.result
      })
    },
    submitForm() {
      console.log(this.form.coverImg)
      if (this.form.title.length < 1) {
        this.$message('标题不能为空')
        return
      }
      if (this.form.content.length < 1) {
        this.$message('内容不能为空')
        return
      }
      if (this.form.type.length < 1) {
        this.$message('分类不能为空')
        return
      }
      if (this.$refs.imgUpload.uploadFiles.length < 1) {
        this.$message('图片不能为空')
        return
      }
      this.$refs.imgUpload.submit()
    }
  }
}
</script>

<style>
.hide .el-upload--picture-card {
  display: none;
}
</style>

<style scoped>
.el-input /deep/ input.el-input__inner {
  border: none;
  border-bottom: 1px solid #cccccc;
}

.el-textarea /deep/ textarea.el-textarea__inner {
  border: none;
}
/*选择地点*/
.place-search {
  position: fixed;
  width: 100vh;
  height: 1px;
  background-color: #fff;
  z-index: 10;
  left: 100vh;
  right: 0;
  bottom: 0;
  transition: left 0.6s;
}
</style>
