//client.js
const OSS = require('ali-oss')
import { request } from '@/network/request'

export function Client(data) {
  //后端提供数据
  return new OSS({
    region: data.region,
    accessKeyId: data.accessKeyId,
    accessKeySecret: data.accessKeySecret,
    stsToken: data.stsToken,
    bucket: data.bucket
  })
}

export function getAliToken() {
  return request({
    url: '/tokenServer'
  })
}
