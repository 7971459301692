<template>
  <div :style="conheight">
    <div class="search-div">
      <el-input id="tipInput" v-model="inputSearchVal" placeholder="请输入搜索名称" @input="searchKeyword"> </el-input>

      <el-button slot="append" icon="el-icon-arrow-left" @click="btnout" style="margin-left:10px">
        返回
      </el-button>
    </div>
    <el-card class="box-card" :style="{ height: conheight.height + 'px' }">
      <div v-for="o in addressList" @click="selectItem(o)" :key="o.id" class="text item">
        <div>
          <div class="name">{{ o.name }}</div>
          <div class="address">{{ o.address }}</div>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: 'mapDetail',
  data() {
    return {
      // 地图map对象
      map: undefined,
      // 组件
      placeSearchComponent: undefined,
      city: '',
      cpoint: [116.38, 39.9],
      // 样式
      conheight: {
        height: '600px',
        width: ''
      },
      // marker点集合
      addressList: [],
      inputSearchVal: ''
    }
  },
  created() {
    window.addEventListener('resize', this.handleResize())

    this.getLocation()
  },
  methods: {
    getLocation() {
      const self = this
      window.AMap.plugin('AMap.Geolocation', function() {
        var geolocation = new window.AMap.Geolocation({
          enableHighAccuracy: true,
          timeout: 10000
        })

        geolocation.getCurrentPosition()
        window.AMap.event.addListener(geolocation, 'complete', onComplete)
        window.AMap.event.addListener(geolocation, 'error', onError)

        function onComplete(data) {
          // data是具体的定位信息
          self.city = data.city
          self.cpoint = data.position
          self.mapSearchInit()
        }

        function onError() {
          self.getLngLatLocation()
        }
      })
    },
    getLngLatLocation() {
      const self = this
      window.AMap.plugin('AMap.CitySearch', function() {
        var citySearch = new window.AMap.CitySearch()
        citySearch.getLocalCity(function(status, result) {
          if (status === 'complete' && result.info === 'OK') {
            self.city = result.city
            self.cpoint = result.rectangle.split(';')[0].split(',')
            self.mapSearchInit()
          }
        })
      })
    },
    btnout() {
      this.$emit('btnout')
    },
    selectItem(item) {
      this.$emit('btnout', item)
    },
    mapSearchInit() {
      const self = this
      window.AMap.plugin(['AMap.Autocomplete', 'AMap.PlaceSearch'], function() {
        self.placeSearchComponent = new window.AMap.PlaceSearch({
          city: self.city,
          pageSize: 20,
          pageIndex: 1
        })
      })
      self.searchKeyword()
    },
    /** 关键词搜索 */
    searchKeyword() {
      var self = this
      self.placeSearchComponent.searchNearBy(self.inputSearchVal, self.cpoint, 1000, function(status, result) {
        if (result && result.info === 'OK') {
          self.addressList = result.poiList.pois
        } else {
          self.addressList = []
        }
      })
    },
    handleResize() {
      this.conheight.width = document.documentElement.clientWidth
      this.conheight.height = document.documentElement.clientHeight
    }
  }
}
</script>

<style>
.search-div {
  display: flex;
  align-items: center;
  width: 50%;
  height: 60px;
  padding: 10px 20px;
  box-sizing: border-box;
}
#container {
  width: 300px;
  height: 180px;
}

.text {
  font-size: 14px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}

.box-card {
  overflow: scroll;
}
.box-card .item {
  border-bottom: 1px solid #ccc;
  padding: 12px;
}
.box-card .name {
  line-height: 24px;
  font-weight: bold;
}
.box-card .address {
  line-height: 24px;
  font-size: 12px;
}
</style>
